<template>
  <div class="cishu_warp">
    <SubjectCard ref="SubjectCardRef"
                 @initList="getInfo" />
    <div class="txt">
      <!-- {{summary[index]?summary[index].conclusion:''}} -->
      <ol style="list-style: auto;">
        <li v-for="(item,index) in summary"
            :key="index">{{item}}</li>
      </ol>
      <div v-if="noExpreience" style="display: flex;justify-content: center;margin-top: 100rem;">
        <button class="btn"
                @click="btnclick">导出{{btnName}}电子教辅</button>
      </div>
    </div>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import SubjectCard from '@/views/components/subject_card/indexCp.vue'
import { getUserSubjectComment, getStudyReport } from '@/api/analysis.js'
import Dialog from './dialog.vue'
import { saveAs } from "file-saver"
export default {
  data () {
    return {
      subjectList: [],
      activeName: '',
      summary: [],
      index: 0,
      btnName: '语文',
      subjectId: 7,
      nickname: '',
      noExpreience: true,
    }
  },
  components: {
    SubjectCard,Dialog
  },
  created () {
    let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.nickname = userinfo.nickname
    if (userinfo.user_type_id == 16) {
      this.noExpreience = false
    }
  },
  props: ['value2', 'value1'],
  methods: {
    check (id, index) {
      this.index = index ? index : 0
    },
    refresh () {
      this.$refs.SubjectCardRef.refresh()
    },
    base64ToBlob (base64) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: 'application/octet-stream' });
    },
    async btnclick () {
      let params = {
        subject_id: this.subjectId,
        first_begin_time: this.value1[0],
        first_end_time: this.value1[1],
        second_begin_time: this.value2[0],
        second_end_time: this.value2[1],

      }
      // await getStudyReport(params).then(res => {
      //   console.log('res', res)
      //   const a = document.createElement("a")
      //   a.href = window.URL.createObjectURL(res.base64Content)
      //   a.download = `${this.list.paper_name || '教师试卷'}.docx`
      //   a.click()

      //   // let blob = new Blob([data], { type: 'application/msword;charset=utf-8' });
      //   // saveAs(blob, `${this.nickname}${this.btnName}善利学情分析报告.docx`)

      // })
      this.$refs.Dialog.dialogVisible = true
      this.$refs.Dialog.params = params
      this.$refs.Dialog.userinfo = {
        nickname: this.nickname,
        btnName: this.btnName
      }

    },

    async getInfo (id, subject_name) {
      // this.$http({
      //   url: '/api/v1/report/subject_conclusion',
      //   method: 'get',
      //   params: {
      //     to_compare_start_time: this.$parent.value1 ? this.$parent.value1[0] : '',
      //     to_compare_end_time: this.$parent.value1 ? this.$parent.value1[1] : '',
      //     be_compared_start_time: this.$parent.value2 ? this.$parent.value2[0] : '',
      //     be_compared_end_time: this.$parent.value2 ? this.$parent.value2[1] : '',
      //   }
      // }).then(res => {
      //   this.summary = res.data.list
      // })
      let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let gradeId = Number(userinfo.edu_grade_id)
      let userId = Number(userinfo.user_id)
      this.subjectId = Number(id)
      const { data } = await getUserSubjectComment({
        thisTimeStart: this.$parent.value1 ? this.$parent.value1[0] : '',
        thisTimeEnd: this.$parent.value1 ? this.$parent.value1[1] : '',
        subjectId: Number(id),
        gradeId,
        userId
      })
      this.summary = data
      this.btnName = subject_name || "语文"
      console.log('subject_name', subject_name)
    },
  }
}
</script>

<style lang="scss" scoped>
.txt {
  font-size: 24rem;
  line-height: 40rem;
  margin: 35rem 80rem 35rem 126rem;
}
.btn {
  padding: 6rem 20rem;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  border: 1px solid #999999;
  font-size: 16rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24rem;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // margin-left: 700rem;
  // margin-top: 80rem;
}
</style>